<template>
    <div class="page-Index">
        <Banner style="position: relative;z-index: 99;"/>
        <Honor style="position: relative;z-index: 99;"/>
        <Trends style="position: relative;z-index: 99;"/>
        <labelPage style="position: relative;z-index: 99;"/>
        <Cooperate style="position: relative;z-index: 99;"/>
        <Special style="position: relative;z-index: 99;"/>
        <rain :rainNumber="200" :w="5" :h="5" style="width: 100%;height:100%;position: fixed;top: 0;left: 0;z-index: 9;"></rain>
    </div>
</template>
  
<script>
import Banner from './benner.vue'
import Honor from './honor.vue'
import Trends from './trends.vue'
import Cooperate from './cooperate.vue'
import Special from './special.vue'
import labelPage from './labelPage.vue'
import rain from '../../layout/components/rain.vue'
export default {
    name: "Index",
    components: {
        Banner,
        Honor,
        Trends,
        Cooperate,
        Special,
        labelPage,
        rain
    },
    data() {
      return {
        
      };
    },
    mounted() {
      
    },
    methods: {
      
    }
};
</script>
  
<style scoped lang="scss">
.page-Index{
    position: relative;
    background: url('../../assets/img/index/bg-box.jpg');
    background-size:  100% 100%;
    background-repeat: no-repeat;
    background-position:0 220px;
}
</style>
  
  