<template>
    <div class="labelPage">
        <div class="left">
          <div :class="current==index?'left-nav left-nav-choose':'left-nav'" v-for="(item,index) in list" @mouseenter="current=index">{{item.name}}</div>
        </div>
        <div class="right">
          <div class="r-left"></div>
          <div class="r-right"></div>
          <div class="r-content">
            <div class="contents" v-for="(item,index) in list[current].nlist">
              <div class="left-con">{{ item.nname }}</div>
              <div class="right-con" v-for="(items,indexs) in item.tlist">
                
                <div class="con-link">{{ items }}  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: "labelPage",
    components: {

    },
    data() {
      return {
        current:0,
        list:[
          {
            name:'版权数字化服务',
            nlist:[
              {
                nname:'版权存证',
                tlist:['版权存证（版权区块链存证）']
              },
              {
                nname:'版权登记',
                tlist:['美术作品登记','文字作品登记','音乐作品登记电影作品登记','作品登记加急']
              },
              {
                nname:'软件著作权',
                tlist:['网站','电脑软件','APP','源代码']
              },
            ]
          },
          {
            name:'数字化维权',
            nlist:[
              {
                nname:'版权监测',
                tlist:['图片监测','文字监测','音乐监测','视频监测']
              },
              {
                nname:'电脑端取证',
                tlist:['网页取证','过程录屏取证']
              },
              {
                nname:'手机端取证',
                tlist:['录音取证','拍照取证','录像取证','录屏取证']
              },
            ]
          },
          {
            name:'版权数字化交易',
            nlist:[
              {
                nname:'作品管理',
                tlist:['图片管理','文字管理','音乐管理','视频管理']
              },
              {
                nname:'作品发布',
                tlist:['作品发布']
              },
              {
                nname:'作品交易',
                tlist:['购买作品']
              },
            ]
          }
        ]
      };
    },
    mounted() {
      
    },
    methods: {
      
    }
  };
</script>
  
<style scoped lang="scss">
.labelPage{
    width: 1400px;
    // height: 935px;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 30px 0;
    .right{
      width: calc(100% - 170px);
      height: 330px;
      margin-top: 20px;
      position: relative;
      .r-content{
        width: 1154px;
        height: 315px;
        padding: 80px 40px;
        position: absolute;
        left: 50px;
        top: 0;
        z-index: 9;
        .contents{
          display: flex;
          flex-wrap: nowrap;
          .left-con{
            width: 150px;
            font-size: 24px;
            font-family: FZCuSong-B09;
            font-weight: 600;
            color: #333333;
            margin-bottom: 20px;
          }
          .right-con{
            font-size: 20px;
            font-family: FZCuSong-B09;
            font-weight: 400;
            color: #999999;
            line-height: 35px;
            .con-link{
              text-indent:1em;
            }
            .con-link:hover{
              color: #000;
              // background-image:url('../../assets/img/index/bq-left.png');
              // background-repeat: no-repeat;
              // background-size: 100% 100%;
            }
          }
        }
      }
      .r-left{
        width: 972px;
        height: 259px;
        background-image:url('../../assets/img/index/bq-left.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 10px;
        bottom: 0;
      }
      .r-right{
        width: 1154px;
        height: 315px;
        background-image:url('../../assets/img/index/bq-right.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 10px;
        top: 0;
      }
    }
    .left{
      width: 170px;
      margin-top: 50px;
      .left-nav{
        font-size: 24px;
        font-family: FZCuSong-B09;
        font-weight: 600;
        color: #333333;
        margin-bottom: 35px;
        position: relative;
      }
      .left-nav-choose::after{
        content:' ';
        white-space: pre;
        width: 171px;
        height: 13px;
        position: absolute;
        left: 0;
        bottom: -15px;
        background-image:url('../../assets/img/index/bq-line.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .left-nav:hover::after{
        content:' ';
        white-space: pre;
        width: 171px;
        height: 13px;
        position: absolute;
        left: 0;
        bottom: -15px;
        background-image:url('../../assets/img/index/bq-line.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    
}
</style>
  
  