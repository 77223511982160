<template>
    <div class="honor">
        <div class="box">
            <div class="ry-left"></div>
            <!-- <img src="../../assets/img/index/ry-left.png" alt="" class="ry"> -->
            <div class="title">
                <img src="../../assets/img/index/ry-title-bg.png" alt="" class="ry-title-bg">
                <div class="name">荣誉资质</div>
                <div class="enname">HONORARY QUALIFICATIONS</div>
            </div>
            <div class="ry-right"></div>
            <!-- <img src="../../assets/img/index/ry-right.png" alt="" class="ry"> -->
        </div>
        <div class="context">
            <div class="text" v-for="(item,index) in list" @click="goInfo(item)">{{item.honorName}}</div>
        </div>
        <!-- <img src="../../assets/img/index/ry-line.png" alt="" class="line"> -->
        <div class="line"></div>

        <el-dialog
            :title="infoData.honorName"
            :visible.sync="dialogVisible"
            width="30%"
            height="300"
            :before-close="handleClose"
            append-to-body
            center>
            <div v-html="infoData.honorBrief"></div>
            <!-- <span>这是一段信息</span> -->
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>

        <!-- <rain :rainNumber="200" :w="5" :h="5" style="width: 100vw;height:100%;"></rain> -->
    </div>
</template>
  
<script>
import {getHonorList,gethonorInfo} from '@/api'
import rain from '../../layout/components/rain.vue'
  export default {
    name: "Honor",
    components: {
        rain
    },
    data() {
      return {
        list:[],
        dialogVisible: false,
        infoData:{}
      };
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList(){
        getHonorList({}).then(res=>{
            // console.log(res)
            if(res.code==200){
                this.list=res.data
            }
        })
      },
      goInfo(row){
        gethonorInfo({
            honorId:row.honorId
        }).then(res=>{
            if(res.code==200){
                console.log(res)
                this.infoData=res.data
                this.dialogVisible=true
            }
        })
        
        // this.$router.push({path:'honorInfo',query:{id:row.honorId}})
      }
    }
  };
</script>
  
<style scoped lang="scss">
.honor{
    width: 100%;
    position: relative;
    margin-top: -200px;
    z-index: 9;
    .title{
        position: relative;
        .name{
            font-size: 40px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #333333;
            text-align: center;
            margin-top: 30px;
        }
        .enname{
            font-size: 20px;
            font-family: FZHuaLi-M14;
            font-weight: 400;
            color: #333333;
            opacity: 0.6;
            text-align: center;
        }
        .ry-title-bg{
            width: 88px;
            height: 88px;
            position: absolute;
            left: 35px;
            // top: 1px;
        }
    }
    .box{
        display: flex;
        justify-content: space-between;
        .ry-left{
            width: 751px;
            height: 433px;
            background-size: 100%;
            background-image: url('../../assets/img/index/ry-left.png');
            background-repeat: no-repeat;
        }
        .ry-right{
            width: 751px;
            height: 433px;
            background-size: 100%;
            background-image: url('../../assets/img/index/ry-right.png');
            background-repeat: no-repeat;
        }
    }
    .context{
        width: 70%;
        height: 140px;
        // background: red;
        position: absolute;
        left: 50%;
        bottom: 80px;
        transform: translateX(-50%);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        .text{
            width: 33.33%;
            font-size: 20px;
            font-family: FZCuSong-B09;
            font-weight: 400;
            color: #333333;
            text-align: center;
        }
        .text:hover{
            color:#C30D23;
        }
    }
    .line{
        width: 1202px;
        height: 13px;
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translateX(-50%);
        background: url('../../assets/img/index/ry-line.png') no-repeat;
        background-size:  100% 100%;
    }
}
</style>
  
  