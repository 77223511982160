<template>
    <div class="cooperate">
        <div class="title">
            <div class="name">合作伙伴</div>
            <div class="enname">COOPERATIVE PARTNER</div>
        </div>
        <div>
            <!-- <marquee behavior="scroll" direction="left" onMouseOut="this.start()" onMouseOver="this.stop()" >
                
                <div :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in toplist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>
            </marquee>
            <marquee style="margin-top: 20px;" behavior="scroll" direction="right" onMouseOut="this.start()" onMouseOver="this.stop()">
                
                <div :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in bottomlist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>

            </marquee> -->
        <!-- <article class="wrapper" @mouseenter="stop=true" @mouseleave="stop=false"> -->
        <article class="wrapper">
            <div class="marquee">
                <div :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in toplist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>
                <div  aria-hidden="true" :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in toplist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>
            </div>

            <div class="marquee marquee--reverse">
                <div :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in bottomlist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>
                <div aria-hidden="true" :class="stop?'marquee__group_stop':'marquee__group'">
                    <div style="width: 250px;height: 100px;" v-for="(item,index) in bottomlist">
                        <img :src="item.partnerPic" alt="" style="width:100%;height:100%;" @click="goInfo(item)">
                    </div>
                </div>
            </div>
        </article>
        </div>

        <el-dialog
            :title="infoData.partnerName"
            :visible.sync="dialogVisible"
            width="30%"
            height="300"
            :before-close="handleClose"
            append-to-body
            center>
            <div v-html="infoData.partnerDesc"></div>
            <!-- <span>这是一段信息</span> -->
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>

        <!-- <rain :rainNumber="200" :w="5" :h="5" style="width: 100vw;height:100%;"></rain> -->
    </div>
</template>
<script>
import {getPartnerList,getPartnerInfo} from '@/api'
import rain from '../../layout/components/rain.vue'
  export default {
    name: "Cooperate",
    components: {
        rain
    },
    data() {
      return {
        stop:false,
        dialogVisible:false,
        infoData:{},
        toplist:[
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
        ],
        bottomlist:[
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
            // require('../../assets/img/index/vivo.png'),
            // require('../../assets/img/index/oppo.png'),
        ]
      };
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList(){
        getPartnerList({}).then(res=>{
            // console.log(res)
            if(res.code==200){
                res.data.forEach((item,index) => {
                    if(index % 2 == 1){
                        // console.log(item)
                        this.toplist.push(item)
                    }else{
                        this.bottomlist.push(item)
                    }
                })
            }
        })
      },
      goInfo(row){
        getPartnerInfo({
            partnerId:row.partnerId
        }).then(res=>{
            if(res.code==200){
                console.log(res)
                this.infoData=res.data
                this.dialogVisible=true
            }
        })
        
        // this.$router.push({path:'honorInfo',query:{id:row.honorId}})
      }
    }
  };
</script>
  
<style scoped lang="scss">
@import './components/style.css';

.cooperate{
    width: 1400px;
    // height: 935px;
    margin: 0 auto;
    position: relative;
    .title{
        width: 334px;
        height: 88px;
        background-image: url('../../assets/img/index/hz-title.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 15px auto 40px auto;
        .name{
            font-size: 30px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 95px;
            text-indent:2em;
        }
        .enname{
            text-indent:2em;
            font-size: 16px;
            font-family: FZHuaLi-M14;
            font-weight: 400;
            color: #333333;
            opacity: 0.5;
            margin-top: -20px;
        }
    }   
}
</style>
  
  