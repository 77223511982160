<template>
    <div class="special">
        <div class="title">
            <div class="name">专题专栏</div>
            <div class="enname">SPECIAL COLUMN</div>
        </div>
        <div class="box">
            <div class="box-i" v-for="(item,index) in list" @click="goInfo(item)">
                <!-- <el-tooltip class="item" effect="dark" :content="item.topicName" placement="top"> -->
    
                    <img src="../../assets/img/index/zt-bg.png" alt="" class="bg">
                    <img :src="item.thumbnailUrl" alt="" class="con">
                <!-- </el-tooltip> -->
            </div>
        </div>
        <div class="btn" @click="goList"> - 查看更多 - </div>
    </div>
</template>
<script>
import {getTopicList} from '@/api'
  export default {
    name: "Special",
    components: {

    },
    data() {
      return {
        list:[
            // require('../../assets/img/index/555.jpg'),
            // require('../../assets/img/index/1263307330993_mthumb.jpg'),
            // require('../../assets/img/index/gyh.png'),
            // require('../../assets/img/index/TBhKRAZnEj4Ica2fa5d51a4afe54e024c84fcfe98c81.png'),
        ]
      };
    },
    mounted() {
      this.getList()
    },
    methods: {
        getList(){
            getTopicList({}).then(res=>{
                // console.log(res)
                if(res.code==200){
                    this.list=res.data
                }
            })
        },
        goList(){
            this.$router.push({path:'special',query:{}})
        },
        goInfo(row){
            this.$router.push({path:'specialInfo',query:{id:row.topicId}})
        }
    }
  };
</script>
  
<style scoped lang="scss">
.special{
    width: 1400px;
    // height: 935px;
    margin: 0 auto;
    position: relative;
    margin-top: 80px;
    .title{
        width: 334px;
        height: 88px;
        background-image: url('../../assets/img/index/hz-title.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 15px auto 40px auto;
        .name{
            font-size: 30px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 95px;
            text-indent:2em;
        }
        .enname{
            text-indent:3.4em;
            font-size: 16px;
            font-family: FZHuaLi-M14;
            font-weight: 400;
            color: #333333;
            opacity: 0.5;
            margin-top: -20px;
        }
    }
    .box{
        display: flex;
        justify-content: space-between;
        .box-i{
            width: 333px;
            height: 351px;
            // background-image: url('../../assets/img/index/zt-bg.png');
            // background-repeat: no-repeat;
            // background-size: 100%;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            transition:all 500ms ease;
            // .item{
            //     width: 333px;
            //     height: 351px;
            //     border-radius: 50%;
            //     overflow: hidden;
            //     transition:all 500ms ease;
                .bg{
                    width: 333px;
                    height: 351px;
                    position:absolute;
                    left: 0;
                    top: 0;
                    z-index: 10;
                    // transition:all 500ms ease;
                    
                }
                .con{
                    width: 100%;
                    height: 100%;
                    padding: 30px;
                    border-radius: 50%;
                    position:absolute;
                    left: 10px;
                    top: 0;
                    z-index: 9;
                    
                }
            // }
            
            
        }
        .box-i:hover{
            transition:all 1000ms ease;
            transform: scale(1.1);
        }
    }
    .btn{
            width: 174px;
            height: 44px;
            background-image: url('../../assets/img/index/dt-btn-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 44px;
            font-size: 13px;
            font-family: SimSun;
            font-weight: 400;
            color: #FFFFFF;
            margin: 40px auto 20px auto;
    }
}
</style>
  
  