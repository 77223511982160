<template>
    <div class="banner">
        <!-- <img src="../../assets/img/index/banner.png" alt="" class="img"> -->
        <div class="img"></div>
        <div class="img-title"></div>
        <!-- <img src="../../assets/img/index/banner-title.png" alt="" class="img-title"> -->
    </div>
</template>
  
<script>
  export default {
    name: "Banner",
    components: {

    },
    data() {
      return {
        
      };
    },
    mounted() {
      
    },
    methods: {
      
    }
  };
</script>
  
<style scoped lang="scss">
.banner{
    width: 100%;
    height: 935px;
    margin-top: -35px;
    position: relative;
    .img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 9;
        // background: url('../../assets/img/index/banner.png') no-repeat;
        background: url('https://file.cacc.shop/pdf/20230509155406.gif') no-repeat;
        background-size:  100% 100%;
        
        
    }
    .img-title{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 99;
        background: url('../../assets/img/index/banner-title.png') no-repeat;
        background-size:  100% 100%;
    }
}
</style>
  
  