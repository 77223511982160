<template>
    <div class="trends">
       <div class="box" v-for="(item,index) in list">
            <div class="title">{{ item.name }}</div>
            <ul>
                <li v-for="(items,indexs) in item.clist" @click="goInfo(items)">{{ items.articleTitle }}</li>
            </ul>
            <div class="btn" @click="goList(item)"> - 查看更多 - </div>
       </div>
       <div class="line"></div>
    </div>
</template>
  
<script>
import {getNewList} from '@/api'
  export default {
    name: "Banner",
    components: {

    },
    data() {
      return {
        list:[
            {
                name:'要闻动态',
                clist:[]
            },
            {
                name:'华版生态',
                clist:[]
            },
            {
                name:'实时热点',
                clist:[]
            },
        ]
      };
    },
    mounted() {
      this.getList(1)
      this.getList(2)
      this.getList(3)
    },
    methods: {
      getList(type){
        getNewList({
            channelId:type
        }).then(res=>{
            // console.log(res)
            if(res.code==200){
                if(type==1){
                    this.list.forEach((item,index)=>{
                        if(item.name=='要闻动态'){
                            item.clist=res.data
                        }
                    })
                }else if(type==2){
                    this.list.forEach((item,index)=>{
                        if(item.name=='华版生态'){
                            item.clist=res.data
                        }
                    })
                }else if(type==3){
                    this.list.forEach((item,index)=>{
                        if(item.name=='实时热点'){
                            item.clist=res.data
                        }
                    })
                }
            }
            
        })
      },
      goInfo(row){
        this.$router.push({path:'trendsInfo',query:{id:row.articleId}})
      },
      goList(item){
        if(item.name=='要闻动态'){
            this.$router.push({path:'trends',query:{}})
        }else if(item.name=='华版生态'){
            this.$router.push({path:'ecology',query:{}})
        }else if(item.name=='实时热点'){
            this.$router.push({path:'hotspot',query:{}})
        }
        
      }
    }
  };
</script>
  
<style scoped lang="scss">
.trends{
    width: 1400px;
    // height: 935px;
    position: relative;
    margin: 0 auto;
    // background: rgba(0,255,0,.6);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-bottom: 40px;
    .box{
        width: 33.33%;
        // display: flex;
        // justify-content: center;
        .title{
            width: 347px;
            height: 53px;
            font-size: 30px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #FFFFFF;
            // text-align: center;
            text-indent:2em;
            background-image: url('../../assets/img/index/dt-nav-bg.png');
            background-repeat: no-repeat;
            background-size: 100%;
            line-height: 53px;
            margin: 0 auto;
        }
        ul{
            // width: 438px;
            width: 100%;
            height: 460px;
            padding: 32px 43px;
            li{
                height: 40px;
                line-height: 22px;
                font-size: 16px;
                font-family: FZCuSong-B09;
                font-weight: 400;
                color: #666666;
                margin-bottom: 44px;
                // overflow: hidden;
                // -webkit-line-clamp: 2;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
            }
            li::marker{
                color: #9D1610;
            }
            li:hover{
                color: #9D1610;
            }
        }
        .btn{
            width: 126px;
            height: 36px;
            background-image: url('../../assets/img/index/dt-btn-bg.png');
            background-repeat: no-repeat;
            background-size: 100%;
            text-align: center;
            line-height: 36px;
            font-size: 13px;
            font-family: SimSun;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0 auto 20px auto;
        }
    }
    .box:hover{
        width: 33.33%;
        // display: flex;
        // justify-content: center;
        .title{
            width: 347px;
            height: 53px;
            font-size: 30px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #FFFFFF;
            // text-align: center;
            text-indent:2em;
            background-image: url('../../assets/img/index/dt-nav-bg-i.png');
            background-repeat: no-repeat;
            background-size: 100%;
            line-height: 53px;
        }
    }
    .line{
        width: 1398px;
        height: 29px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: url('../../assets/img/index/line-two.png') no-repeat;
        background-size:  100% 100%;
    }
}
</style>
  
  